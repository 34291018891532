import { Card, Center, Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearIhaleDetay,
  getIhaleSonucByIdRequest,
} from "../store/ihaleSonucReducer";
import { useParams } from "react-router-dom";
import SonucTable from "../components/SonucTable";
import { getFirmaOptionsRequest } from "../store/firmaReducer";

const IhaleSonucDetayPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { ihaleDetay, ihaleDetayLoading } = useSelector(
    (state) => state.ihaleSonuc
  );

  useEffect(() => {
    dispatch(getFirmaOptionsRequest()).then(() => {
      dispatch(getIhaleSonucByIdRequest({ id }));
    });
    return () => {
      dispatch(clearIhaleDetay());
    };
  }, []);

  if (!ihaleDetay && ihaleDetayLoading) {
    return (
      <Center>
        <Spinner size={"lg"} />
      </Center>
    );
  }
  return (
    <Card mb={8}>
      <SonucTable data={ihaleDetay} isView={true} />
    </Card>
  );
};

export default IhaleSonucDetayPage;
