import {
  Button,
  Center,
  Container,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFirmaOptionsRequest } from "../store/firmaReducer";
import { getDataRequest } from "../store/teklifReducer";
import { NumericFormat } from "react-number-format";
import SonucTable from "../components/SonucTable";

const TEKLİF_GEÇERSİZ = 2;
const KAZANAMADI = 1;
const KAZANDI = 0;

const HesaplaPage = () => {
  const dispatch = useDispatch();
  const { teklifData, teklifDataLoading } = useSelector(
    (state) => state.teklif
  );
  const [sonucTablosu, setSonucTablosu] = useState({});
  const [maliyet, setMaliyet] = useState({});
  const [hesaplamaTamamlandi, setHesaplamaTamamlandi] = useState(null);

  const teklifleriHesapla = (e) => {
    e.preventDefault();
    setHesaplamaTamamlandi(false);
    const sonuc = teklifData
      ?.toSorted((oncekifirma, sonrakifirma) => {
        if (sonrakifirma.teklifi > oncekifirma.teklifi) {
          //teklifi büyükse bu negatif bir durumdur.
          return -1;
        } else if (sonrakifirma.teklifi < oncekifirma.teklifi) {
          //teklifi küçükse bu pozitif bir durumdur.
          return 1;
        } else if (sonrakifirma.teminatTutari < oncekifirma.teminatTutari) {
          //teklifleri eşit ama teminat tutarı küçükse bu negatif bir durumdur.
          return -1;
        } else if (sonrakifirma.teminatTutari > oncekifirma.teminatTutari) {
          //teklifleri eşit ama teminat tutarı büyükse bu pozitif bir durumdur.
          return 1;
        } else {
          //teklif ve teminat tutarları eşitse karşılaştırma nötrdür.
          return 0;
        }
      })
      .map((teklif) => {
        const { _id, firmaId, teklifi, teminatTutari } = teklif;

        if (teminatTutari < teklifi * 0.03) {
          return {
            _id,
            firmaId,
            teklifi,
            teminatTutari,
            durumu: TEKLİF_GEÇERSİZ,
          };
        }

        if (teklifi > maliyet.floatValue) {
          return {
            _id,
            firmaId,
            teklifi,
            teminatTutari,
            durumu: KAZANAMADI,
          };
        }

        return {
          _id,
          firmaId,
          teklifi,
          teminatTutari,
          durumu: KAZANDI,
        };
      })
      //duruma göre sıralama yap.
      .sort((oncekifirma, sonrakifirma) => {
        if (sonrakifirma.durumu > oncekifirma.durumu) {
          return -1;
        } else if (sonrakifirma.durumu < oncekifirma.durumu) {
          return 1;
        } else {
          return 0;
        }
      })
      //Eğer kazanan birden fazla ise durumunu Kazanamadı olarak değiştir.
      .map(({ _id, firmaId, teklifi, teminatTutari, durumu }, satirsirasi) =>
        satirsirasi !== 0 && durumu === KAZANDI
          ? { _id, firmaId, teklifi, teminatTutari, durumu: KAZANAMADI }
          : { _id, firmaId, teklifi, teminatTutari, durumu }
      );

    //Eğer sonucunda kazanan yoksa durumu ihale durumu belirlenir.
    const durumu =
      sonuc.filter(({ _id }) => _id === "6596df1b0f41c9a5afe3a373").length === 1
        ? "TAMAMLANDI"
        : "İPTAL";

    setSonucTablosu({
      sonuc,
      durumu,
    });
    // Hesaplama tamamlandıktan sonra tabloyu göstermek için.
    setTimeout(() => {
      setHesaplamaTamamlandi(true);
    }, 1000);
  };

  useEffect(() => {
    dispatch(getFirmaOptionsRequest(true)).then(() => {
      dispatch(getDataRequest());
    });
  }, []);

  if (teklifData.length === 0 && teklifDataLoading) {
    return (
      <Center>
        <Spinner size={"lg"} />
      </Center>
    );
  }
  return (
    <>
      <Container mb={4}>
        <form onSubmit={teklifleriHesapla}>
          <InputGroup size="lg">
            <NumericFormat
              required={true}
              customInput={Input}
              autoComplete="off"
              prefix="₺"
              thousandSeparator="."
              decimalSeparator=","
              fixedDecimalScale
              decimalScale={2}
              onValueChange={(values) => setMaliyet(values)}
              isDisabled={hesaplamaTamamlandi !== null}
              name="maliyet"
              size={"lg"}
              pr="5rem"
              placeholder="Yaklaşık Maliyet Tutarı (TL)"
            />
            <InputRightElement width="auto">
              <Button
                isLoading={hesaplamaTamamlandi === false}
                mr={1}
                type="submit"
              >
                Hesapla
              </Button>
            </InputRightElement>
          </InputGroup>
        </form>
      </Container>
      {hesaplamaTamamlandi === false && (
        <Center>
          <Heading size={"md"} color="gray">
            Hesaplama Yapılıyor...
          </Heading>
        </Center>
      )}
      {hesaplamaTamamlandi ? (
        <SonucTable
          data={{ ...sonucTablosu, maliyet: maliyet.formattedValue }}
        />
      ) : null}
    </>
  );
};

export default HesaplaPage;
