import {
  Badge,
  Button,
  Card,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { DURUMLAR, getWithCurrencyFormat } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx/xlsx.mjs";
import { addIhaleSonucRequest } from "../store/ihaleSonucReducer";
import { CheckIcon, DownloadIcon } from "@chakra-ui/icons";
import { getFirmaOptionsRequest } from "../store/firmaReducer";

const TEKLİF_GEÇERSİZ = 2;
const KAZANAMADI = 1;
const KAZANDI = 0;

const SonucTable = ({ data, isView }) => {
  const { firmaOptions } = useSelector((state) => state.firma);
  const dispatch = useDispatch();
  const tableRef = useRef();
  const [sonucKaydedildiMesaj, setSonucKaydedildiMesaj] = useState(null);

  const exceleAktar = () => {
    const wb = XLSX.utils.table_to_book(tableRef.current);
    XLSX.writeFileXLSX(wb, `İhale-Sonucu-${Date.now()}.xlsx`);
  };
  const sonucuKaydet = () => {
    dispatch(addIhaleSonucRequest({ ...data }))
      .then((res) => {
        res.error ? alert("HATA !") : setSonucKaydedildiMesaj("Kaydedildi!");
      })
      .catch((err) => console.log("Err", err));
  };

  useEffect(() => {
    dispatch(getFirmaOptionsRequest());
  }, []);

  return (
    <>
      <Card>
        <TableContainer>
          <Table variant="simple" size={"lg"}>
            <Thead>
              <Tr>
                <Th colSpan={4}>İHALE DEĞERLENDİRME TABLOSU</Th>
                <Th colSpan={1} textAlign={"right"}>
                  <Button onClick={exceleAktar} type="button">
                    Excell'e Aktar
                  </Button>
                </Th>
              </Tr>
            </Thead>
          </Table>
          <Table variant="simple" size={"lg"} ref={tableRef}>
            <Thead>
              <Tr>
                <Th colSpan={5}>İHALE DURUMU: {data?.durumu}</Th>
              </Tr>
            </Thead>
            <Thead>
              <Tr>
                <Th>SIRALAMA</Th>
                <Th w="full">FİRMA İSMİ</Th>
                <Th>TEKLİF (TL)</Th>
                <Th>TEMİNAT TUTARI (TL)</Th>
                <Th>FİRMA DURUMU</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.sonuc?.map((x, i) => (
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{firmaOptions[x.firmaId]}</Td>
                  <Td>{getWithCurrencyFormat(x.teklifi)}</Td>
                  <Td>{getWithCurrencyFormat(x.teminatTutari)}</Td>
                  <Td>
                    {" "}
                    <Badge
                      ml="1"
                      fontSize="0.8em"
                      colorScheme={
                        x.durumu === KAZANDI
                          ? "green"
                          : x.durumu === KAZANAMADI
                          ? "orange"
                          : "red"
                      }
                    >
                      {DURUMLAR[x.durumu]}
                    </Badge>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot bg={"gray.100"}>
              <Tr>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td textAlign={"center"}>
                  <Heading size={"md"} fontWeight={"md"}>
                    Yaklaşık Maliyet Tutarı (TL):
                  </Heading>
                </Td>
                <Td>
                  <Heading size={"md"} fontWeight={"md"}>
                    {` ${data.maliyet} `}
                  </Heading>
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Card>
      {!isView ? (
        <Flex mt={4}>
          <Button
            size="lg"
            colorScheme={"green"}
            mx="auto"
            onClick={sonucuKaydet}
            isDisabled={sonucKaydedildiMesaj}
            leftIcon={sonucKaydedildiMesaj ? <CheckIcon /> : <DownloadIcon />}
          >
            {sonucKaydedildiMesaj || <>Veri Tabanına Kaydet</>}
          </Button>
        </Flex>
      ) : null}
    </>
  );
};

export default SonucTable;
