import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api";

export const getDataRequest = createAsyncThunk("firma/getAll", async () => {
  const response = await api.get("/firma/getAll");
  return response.data;
});

export const getFirmaOptionsRequest = createAsyncThunk(
  "firma/getFirmaOptions",
  async (status = true) => {
    const response = await api.get(`/firma/getFirmaOptions?status=${status}`);
    return response.data;
  }
);

export const addFirmaRequest = createAsyncThunk("firma/add", async (arg) => {
  const response = await api.post("/firma/add", {
    ...arg,
  });
  return response.data;
});

export const updateFirmaRequest = createAsyncThunk(
  "firma/updateById",
  async (arg) => {
    const response = await api.put("/firma/updateById", {
      ...arg,
    });
    return response.data;
  }
);

export const deleteFirmaRequest = createAsyncThunk(
  "firma/deleteById",
  async (arg) => {
    const response = await api.get(`/firma/deleteById?id=${arg.id}`, {
      ...arg,
    });
    return response.data;
  }
);

const initialState = {
  firmaData: [],
  firmaDataLoading: false,
  firmaFormActionLoading: false,
  firmaOptions: {},
};

const slice = createSlice({
  name: "firma",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDataRequest.pending, (state, action) => {
      state.firmaDataLoading = true;
    });
    builder.addCase(getDataRequest.fulfilled, (state, action) => {
      state.firmaDataLoading = false;
      state.firmaData = action.payload;
    });
    builder.addCase(addFirmaRequest.pending, (state, action) => {
      state.firmaFormActionLoading = true;
    });
    builder.addCase(addFirmaRequest.fulfilled, (state, action) => {
      state.firmaFormActionLoading = false;
    });
    builder.addCase(addFirmaRequest.rejected, (state, action) => {
      state.firmaFormActionLoading = false;
    });
    builder.addCase(updateFirmaRequest.pending, (state, action) => {
      state.firmaFormActionLoading = true;
    });
    builder.addCase(updateFirmaRequest.fulfilled, (state, action) => {
      state.firmaFormActionLoading = false;
    });
    builder.addCase(updateFirmaRequest.rejected, (state, action) => {
      state.firmaFormActionLoading = false;
    });
    builder.addCase(getFirmaOptionsRequest.fulfilled, (state, action) => {
      state.firmaOptions = action.payload;
    });
  },
});

export default slice.reducer;

export const {} = slice.actions;
