import {
  Badge,
  Button,
  Card,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDateTime } from "../utils";
import { getAllIhaleSonucRequest } from "../store/ihaleSonucReducer";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

const IhaleSonucPage = () => {
  const dispatch = useDispatch();
  const tableRef = useRef();
  const { ihaleSonucData, ihaleSonucDataLoading } = useSelector(
    (state) => state.ihaleSonuc
  );

  useEffect(() => {
    dispatch(getAllIhaleSonucRequest());
  }, []);

  if (ihaleSonucData.length === 0 && ihaleSonucDataLoading) {
    return (
      <Center>
        <Spinner size={"lg"} />
      </Center>
    );
  }
  return (
    <Card mb={8}>
      <TableContainer>
        <Table variant="simple" size={"lg"} ref={tableRef}>
          <Thead>
            <Tr>
              <Th>İHALE</Th>
              <Th>MALİYET (TL)</Th>
              <Th>SONUCU</Th>
              <Th>TARİHİ</Th>
            </Tr>
          </Thead>
          <Tbody>
            {ihaleSonucData?.map((x, i) => (
              <Tr key={i}>
                <Td>
                  <Button
                    as={Link}
                    to={`/sonuclanan-ihaleler/${x._id}`}
                    variant="link"
                    rightIcon={<ExternalLinkIcon />}
                  >
                    {x._id}
                  </Button>
                </Td>
                <Td>{x?.maliyet}</Td>
                <Td>
                  <Badge
                    ml="1"
                    fontSize="0.8em"
                    colorScheme={
                      x.durumu === "TAMAMLANDI"
                        ? "green"
                        : x.durumu === "İPTAL"
                        ? "orange"
                        : "red"
                    }
                  >
                    {x.durumu}
                  </Badge>
                </Td>
                <Td>{formatDateTime(x.createdAt)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default IhaleSonucPage;
