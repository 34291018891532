"use client";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import api from "../api";

const GirisPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const returnUrl = searchParams.get("to");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { kullaniciAdi, sifre } = e.target;
    const response = await api.post("/kullanici/login", {
      kullaniciAdi: kullaniciAdi.value,
      sifre: sifre.value,
    });
    if (response.data.success) {
      window.localStorage.isLoggedIn = true;
      navigate(returnUrl || "/");
    } else {
      alert(response.data.message);
    }
  };
  return (
    <Flex minH={"100vh"} mt={20} align={"start"} justify={"center"}>
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
        as={"form"}
        onSubmit={handleSubmit}
      >
        <Heading
          lineHeight={1.1}
          textAlign={"center"}
          fontSize={{ base: "2xl", md: "3xl" }}
        >
          Giriş Formu
        </Heading>
        <FormControl>
          <FormLabel>Kullanıcı Adı</FormLabel>
          <Input
            type="text"
            required
            name="kullaniciAdi"
            defaultValue="selim"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Şifre</FormLabel>
          <Input
            type="password"
            required
            name="sifre"
            defaultValue="Selim1234!!"
          />
        </FormControl>
        <Stack spacing={6}>
          <Button
            type="submit"
            bg={"blue.400"}
            color={"white"}
            _hover={{
              bg: "blue.500",
            }}
          >
            Giriş Yap
          </Button>

          <Text
            fontSize={"small"}
            textDecoration={"underline"}
            as={Link}
            to={"/"}
            mx={"auto"}
          >
            <span>Ana sayfaya dön</span>
          </Text>
        </Stack>
      </Stack>
    </Flex>
  );
};
export default GirisPage;
