import axios from "axios";
const api = axios.create();

api.interceptors.request.use(
  function (config) {
    config.baseURL = process.env.REACT_APP_API_URL;
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      window.localStorage.removeItem("isLoggedIn");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
