import React from "react";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import HomePage from "./pages/HomePage";
import Layout from "./components/Layout";
import { store } from "./store/index";
import { Provider } from "react-redux";
import { ModalProvider } from "react-modal-hook";
import HesaplaPage from "./pages/HesaplaPage";
import ErrorElement from "./components/ErrorElement";
import FirmalarPage from "./pages/FirmalarPage";
import TeklifGirisiPage from "./pages/TeklifGirisiPage";
import GirisPage from "./pages/GirisPage";
import IhaleSonucPage from "./pages/IhaleSonucPage";
import IhaleSonucDetayPage from "./pages/IhaleSonucDetayPage";

const ProtectedRoute = () => {
  const location = useLocation();

  return window.localStorage.isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/giris-yap?to=${location.pathname + location.search}`}
      replace
      state={{ from: location }}
    />
  );
};
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: "/firmalar",
            element: <FirmalarPage />,
            errorElement: <ErrorElement />,
          },
          {
            path: "/teklif-girisi",
            element: <TeklifGirisiPage />,
            errorElement: <ErrorElement />,
          },
          {
            path: "/ihale-degerlendir",
            element: <HesaplaPage />,
            errorElement: <ErrorElement />,
          },

          {
            path: "/sonuclanan-ihaleler",
            element: <IhaleSonucPage />,
            errorElement: <ErrorElement />,
          },
          {
            path: "/sonuclanan-ihaleler/:id",
            element: <IhaleSonucDetayPage />,
            errorElement: <ErrorElement />,
          },
        ],
        errorElement: <ErrorElement />,
      },
    ],
  },
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
        errorElement: <ErrorElement />,
      },
    ],
  },
  {
    path: "/giris-yap",
    element: <GirisPage />,
    errorElement: <ErrorElement />,
  },
  {
    path: "*",
    element: <ErrorElement />,
  },
]);

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <Provider store={store}>
          <ModalProvider>
            <RouterProvider router={router} />
          </ModalProvider>
        </Provider>
      </ChakraProvider>
    </div>
  );
}

export default App;
