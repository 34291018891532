import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addFirmaRequest, updateFirmaRequest } from "../store/firmaReducer";

const FirmaForm = ({ data, cb }) => {
  const dispatch = useDispatch();
  const { firmaFormActionLoading } = useSelector((state) => state.firma);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firmaAdi, vergiKimlikNo, telefon, adres } = e.target;
    const values = {
      firmaAdi: firmaAdi.value,
      vergiKimlikNo: vergiKimlikNo.value,
      telefon: telefon.value,
      adres: adres.value,
    };
    const { _id } = data;
    if (_id) {
      await dispatch(
        updateFirmaRequest({
          id: _id,
          updatedData: {
            ...values,
          },
        })
      );
    } else {
      await dispatch(
        addFirmaRequest({
          ...values,
        })
      );
    }
    cb();
  };

  return (
    <Box
      w={"100%"}
      as="form"
      display="flex"
      flexDirection={"column"}
      gap={2}
      onSubmit={handleSubmit}
    >
      <FormControl>
        <FormLabel>Firma Adı*</FormLabel>
        <Input
          type="text"
          required
          placeholder="Firma Adı"
          name="firmaAdi"
          defaultValue={data?.firmaAdi ?? ""}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Vergi Kimlik No*</FormLabel>
        <Input
          type="number"
          required
          placeholder="Vergi Kimlik No"
          name="vergiKimlikNo"
          defaultValue={data?.vergiKimlikNo ?? ""}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Telefon *</FormLabel>
        <Input
          type="number"
          required
          placeholder="Telefon"
          name="telefon"
          defaultValue={data?.telefon ?? ""}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Adres *</FormLabel>
        <Textarea
          required
          placeholder="Adres"
          name="adres"
          defaultValue={data?.adres ?? ""}
        />
      </FormControl>

      <Box gap={2} mt={4} justifyContent={"flex-end"}>
        <Button
          type="submit"
          colorScheme="blue"
          mr={3}
          isLoading={firmaFormActionLoading}
        >
          {data._id ? "Güncelle" : "Ekle"}
        </Button>
        <Button type="button" onClick={cb}>
          Vazgeç
        </Button>
      </Box>
    </Box>
  );
};

export default FirmaForm;
