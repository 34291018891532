import {
  Box,
  Button,
  Card,
  Center,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataRequest } from "../store/teklifReducer";
import { AddIcon } from "@chakra-ui/icons";
import TeklifCard from "../components/TeklifCard";
import TeklifForm from "../components/TeklifForm";
import { useModal } from "react-modal-hook";
import CustomModal from "../components/CustomModal";
import { getFirmaOptionsRequest } from "../store/firmaReducer";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const TeklifGirisiPage = () => {
  const [parent] = useAutoAnimate();
  const dispatch = useDispatch();
  const { teklifData, teklifDataLoading } = useSelector(
    (state) => state.teklif
  );
  const [showModal, hideModal] = useModal(
    () => (
      <CustomModal title="Teklif Ekle" isOpen={showModal} onClose={hideModal}>
        <TeklifForm
          data={{}}
          cb={async () => {
            await dispatch(getDataRequest());
            hideModal();
          }}
        />
      </CustomModal>
    ),
    []
  );

  useEffect(() => {
    dispatch(getFirmaOptionsRequest(true)).then(() => {
      dispatch(getDataRequest());
    });
  }, []);

  return (
    <Box>
      {teklifData.length === 0 && teklifDataLoading ? (
        <Center>
          <Spinner size={"lg"} />
        </Center>
      ) : (
        <SimpleGrid
          spacing={4}
          templateColumns="repeat(auto-fit, minmax(320px, 1fr))"
          ref={parent}
        >
          {teklifData?.map((teklif, index) => (
            <TeklifCard data={teklif} key={index} />
          ))}
          <Card textAlign="center" p={4} minH={"186px"}>
            <Heading as="h2" size="lg">
              Teklif ekle
            </Heading>
            <Text color={"gray.500"}>İhaleye girişlerini yapınız.</Text>
            <Button variant="solid" w={"100%"} mt="auto" onClick={showModal}>
              Ekle <AddIcon ml={2} />
            </Button>
          </Card>
        </SimpleGrid>
      )}
    </Box>
  );
};

export default TeklifGirisiPage;
