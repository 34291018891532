import React from "react";
import {
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import Illustration from "../components/Illustration";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <Container maxW={"5xl"}>
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={900}
          fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
          lineHeight={"110%"}
        >
          İHALE DEĞERLENDİRME SİSTEMİ
        </Heading>
        <Text color={"gray.500"} maxW={"3xl"}>
          Bu sistem ile firma ekleyip firmaları ihale sürecine sokup teklif
          verebilir ve sonucunu görebilirsiniz.
        </Text>
        <Stack spacing={6} direction={"row"}>
          <Button
            as={Link}
            to={"/firmalar"}
            rounded={"full"}
            px={6}
            colorScheme={"teal"}
            bg={"teal.400"}
            _hover={{ bg: "teal.500" }}
          >
            Firma ekleyerek başla
          </Button>
        </Stack>
        <Flex w={"full"}>
          <Illustration
            height={{ sm: "16rem", lg: "20rem" }}
            mt={{ base: 12, sm: 16 }}
          />
        </Flex>
      </Stack>
    </Container>
  );
};

export default HomePage;
