import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api";

export const getAllIhaleSonucRequest = createAsyncThunk(
  "ihaleSonuc/getAll",
  async () => {
    const response = await api.get("/ihaleSonuc/getAll");
    return response.data;
  }
);

export const addIhaleSonucRequest = createAsyncThunk(
  "ihaleSonuc/add",
  async (arg) => {
    const response = await api.post("/ihaleSonuc/add", {
      ...arg,
    });
    return response.data;
  }
);

export const updateIhaleSonucRequest = createAsyncThunk(
  "ihaleSonuc/updateById",
  async (arg) => {
    const response = await api.put("/ihaleSonuc/updateById", {
      ...arg,
    });
    return response.data;
  }
);

export const deleteIhaleSonucRequest = createAsyncThunk(
  "ihaleSonuc/deleteById",
  async (arg) => {
    const response = await api.get(`/ihaleSonuc/deleteById?id=${arg.id}`, {
      ...arg,
    });
    return response.data;
  }
);

export const getIhaleSonucByIdRequest = createAsyncThunk(
  "ihaleSonuc/deleteById",
  async (arg) => {
    const response = await api.get(`/ihaleSonuc/getById?id=${arg.id}`, {
      ...arg,
    });
    return response.data;
  }
);

const initialState = {
  ihaleSonucData: [],
  ihaleSonucDataLoading: false,
  ihaleDetay: {},
  ihaleDetayLoading: false,
};

const slice = createSlice({
  name: "ihaleSonuc",
  initialState,
  reducers: {
    clearIhaleDetay: (state, action) => {
      state.ihaleDetay = {};
      state.ihaleDetayLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllIhaleSonucRequest.pending, (state, action) => {
      state.ihaleSonucDataLoading = true;
    });
    builder.addCase(getAllIhaleSonucRequest.fulfilled, (state, action) => {
      state.ihaleSonucDataLoading = false;
      state.ihaleSonucData = action.payload;
    });
    builder.addCase(getIhaleSonucByIdRequest.pending, (state, action) => {
      state.ihaleDetayLoading = true;
    });
    builder.addCase(getIhaleSonucByIdRequest.fulfilled, (state, action) => {
      state.ihaleDetayLoading = false;
      state.ihaleDetay = action.payload;
    });
  },
});

export default slice.reducer;

export const { clearIhaleDetay } = slice.actions;
