import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Container,
  Button,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import api from "../api";
import { useEffect, useState } from "react";

const NavLink = (props) => {
  const location = useLocation();
  const { children, to } = props;
  return (
    <Box
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      bg={location.pathname === to ? "gray.200" : ""}
    >
      <Link to={to}>{children}</Link>
    </Box>
  );
};

export default function Layout(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();
  const menu = [
    { label: "Ana sayfa", url: "/" },
    { label: "Firmalar", url: "/firmalar" },
    { label: "Teklif Girişi", url: "/teklif-girisi" },
    { label: "İhale Değerlendir", url: "/ihale-degerlendir" },
    { label: "Sonuçlanan İhaleler", url: "/sonuclanan-ihaleler" },
  ];
  const onLogout = async () => {
    await api.get("/kullanici/logout");
    setIsLoggedIn(null);
    window.localStorage.removeItem("isLoggedIn");
    window.location = "/";
  };
  const onLogin = async () => {
    navigate("/giris-yap");
  };
  const RenderAuthButton = () => {
    if (isLoggedIn === null || !isLoggedIn) {
      return (
        <Button ml={"auto"} onClick={onLogin}>
          Giriş Yap
        </Button>
      );
    }
    return (
      <Button ml={"auto"} onClick={onLogout}>
        Çıkış Yap
      </Button>
    );
  };
  useEffect(() => {
    setIsLoggedIn(window.localStorage.isLoggedIn ? true : false);
  }, []);

  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "  none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"} w={"100%"}>
            <Box fontWeight={"semibold"}>İDS</Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
              w={"100%"}
            >
              {menu.map((x, i) => (
                <NavLink key={i} to={x.url}>
                  {x.label}
                </NavLink>
              ))}
              <RenderAuthButton />
            </HStack>
          </HStack>
        </Flex>
        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {menu.map((x, i) => (
                <NavLink key={i} to={x.url}>
                  {x.label}
                </NavLink>
              ))}
              <RenderAuthButton />
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Container maxW={"container.xl"} p={4}>
        {isLoggedIn !== null && <Outlet />}
      </Container>
    </>
  );
}
