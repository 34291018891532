import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api";

export const getDataRequest = createAsyncThunk("teklif/getAll", async () => {
  const response = await api.get("/teklif/getAll");
  return response.data;
});

export const addTeklifRequest = createAsyncThunk("teklif/add", async (arg) => {
  const response = await api.post("/teklif/add", {
    ...arg,
  });
  return response.data;
});

export const updateTeklifRequest = createAsyncThunk(
  "teklif/updateById",
  async (arg) => {
    const response = await api.put("/teklif/updateById", {
      ...arg,
    });
    return response.data;
  }
);

export const deleteTeklifRequest = createAsyncThunk(
  "teklif/deleteById",
  async (arg) => {
    const response = await api.get(`/teklif/deleteById?id=${arg.id}`, {
      ...arg,
    });
    return response.data;
  }
);

const initialState = {
  teklifData: [],
  teklifDataLoading: false,
  teklifFormActionLoading: false,
};

const slice = createSlice({
  name: "teklif",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDataRequest.pending, (state, action) => {
      state.teklifDataLoading = true;
    });
    builder.addCase(getDataRequest.fulfilled, (state, action) => {
      state.teklifDataLoading = false;
      state.teklifData = action.payload;
    });
    builder.addCase(addTeklifRequest.pending, (state, action) => {
      state.teklifFormActionLoading = true;
    });
    builder.addCase(addTeklifRequest.fulfilled, (state, action) => {
      state.teklifFormActionLoading = false;
    });
    builder.addCase(addTeklifRequest.rejected, (state, action) => {
      state.teklifFormActionLoading = false;
    });
    builder.addCase(updateTeklifRequest.pending, (state, action) => {
      state.teklifFormActionLoading = true;
    });
    builder.addCase(updateTeklifRequest.fulfilled, (state, action) => {
      state.teklifFormActionLoading = false;
    });
    builder.addCase(updateTeklifRequest.rejected, (state, action) => {
      state.teklifFormActionLoading = false;
    });
  },
});

export default slice.reducer;

export const {} = slice.actions;
